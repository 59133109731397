
// Converted Variables

$darken-1: rgba(0, 0, 0, .0625) !default;
$darken-2: rgba(0, 0, 0, .125) !default;
$darken-3: rgba(0, 0, 0, .25) !default;
$darken-4: rgba(0, 0, 0, .5) !default;

// Custom Media Query Variables


/* Basscss Darken */

.bg-darken-1 { background-color: $darken-1 }

.bg-darken-2 { background-color: $darken-2 }

.bg-darken-3 { background-color: $darken-3 }

.bg-darken-4 { background-color: $darken-4 }