// things put here override variables from fortitude

$atevans-margin-default:   1rem;

$fa-font-path:             "/fonts/";
$fa-font-size-base:        0.7rem;

$font-family-identity:     "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif:   "Arvo", "Helvetica", "Lucida Sans Unicode", sans-serif;
$font-family-sansier:      "Source Sans Pro", "San Francisco", "Helvetica Neue", "Helvetica", sans-serif;
$font-family-serif:        "Palatino Linotype", "Book Antiqua", Palatino, serif;
$font-family-monospace:    "Source Code Pro", Menlo, Monaco, Consolas, "Courier New", monospace;

// Override defaults for BassCss

$font-family:           $font-family-sansier;
$heading-font-family:   $font-family-sans-serif;
$monospace-font-family: $font-family-monospace;

$aqua:    #7fdbff;
$blue:    #0074d9;
$navy:    #001f3f;
$teal:    #39cccc;
$green:   #2ecc40;
$olive:   #3d9970;
$lime:    #01ff70;
$yellow:  #ffdc00;
$orange:  #ff851b;
$red:     #ff4136;
$fuchsia: #f012be;
$purple:  #b10dc9;
$maroon:  #85144b;
$white:   #fff;
$silver:  #f8f8f8;
$gray:    #aaa;
$black:   #111;
$dark:    #333;

$link-color: $fuchsia;

$h1: 2rem !default;
$h2: 1.5rem !default;
$h3: 1.25rem !default;
$h4: 1rem !default;
$h5: .875rem !default;
$h6: .75rem !default;

$breakpoint-sm: '(min-width: 40em)' !default;
$breakpoint-md: '(min-width: 52em)' !default;
$breakpoint-lg: '(min-width: 64em)' !default;

$container-width: 52em;
