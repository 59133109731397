
// Converted Variables

$form-field-height: 2.25rem !default;
$form-field-padding-y: .5rem !default;
$darken-2: rgba(0, 0, 0, .125) !default;

// Custom Media Query Variables


/* Basscss Progress */

.progress {
  display: block;
  width: 100%;
  height: ( calc($form-field-height / 4) );
  margin: $form-field-padding-y 0;
  overflow: hidden;
  background-color: $darken-2;
  border: 0;
  border-radius: 10000px;
  -webkit-appearance: none;
}

.progress::-webkit-progress-bar {
  -webkit-appearance: none;
  background-color: $darken-2
}

.progress::-webkit-progress-value {
  -webkit-appearance: none;
  background-color: currentcolor;
}

.progress::-moz-progress-bar {
  background-color: currentcolor;
}
