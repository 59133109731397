
// Custom Media Query Variables


/* Basscss Border Colors */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/

.border-black  { border-color: $black }

.border-gray   { border-color: $gray }

.border-silver { border-color: $silver }

.border-white  { border-color: $white }

.border-aqua  { border-color: $aqua }

.border-blue  { border-color: $blue }

.border-navy  { border-color: $navy }

.border-teal  { border-color: $teal }

.border-green { border-color: $green }

.border-olive { border-color: $olive }

.border-lime  { border-color: $lime }

.border-yellow  { border-color: $yellow }

.border-orange  { border-color: $orange }

.border-red     { border-color: $red }

.border-fuchsia { border-color: $fuchsia }

.border-purple  { border-color: $purple }

.border-maroon  { border-color: $maroon }
