.post-content{

  a {
    color: $link-color;
  }

  h1 {
    font-size: $h1;
    font-family: $heading-font-family;
  }

  h2 {
    font-size: $h2;
    font-family: $heading-font-family;
  }

  h3 {
    font-size: $h3;
    font-family: $heading-font-family;
  }

  h4 {
    font-size: $h4;
    font-family: $heading-font-family;
  }

  h5 {
    font-size: $h5;
    font-family: $heading-font-family;
  }

  h6 {
    font-size: $h6;
    font-family: $heading-font-family;
  }

  p {
    font-family: $font-family;
  }

  ul > li, ol > li {
    line-height: 1.2rem;
    margin-bottom: 1rem;
  }


  iframe { margin-top: $atevans-margin-default; max-width: 100%; }

  p, li, ul, h1, h2, h3, h4, h5, h6, table {
    code {
      vertical-align:   middle;
      font-family:      $monospace-font-family;
      font-size:        0.8rem;
      padding:          0.1rem 0.2rem 0.1rem 0.2rem;
      background-color: $silver;
      display:          inline-block;
      max-width:        100%;
      overflow:         scroll;
    }
  }

  .highlight {
    padding:          0.5rem;
    font-size:        0.8rem;
    font-family:      $monospace-font-family;
    margin-bottom:    1rem;
    display:          block;

    code {
      padding:        1rem;
      display:        block;
    }
  }

  blockquote {
    padding-right: 1rem;
    padding-left: 1rem;
    font-style: italic;
    border-left: 1px solid $gray;

    margin-bottom: $atevans-margin-default;
  }

  p > img {
    display: block;
    margin-bottom: $atevans-margin-default;
    max-width: 100%;
  }
}
