a { text-decoration: none; }
body { margin: 0; }
h1, h2, h3, h4, h5, h6 { font-weight: normal; }

html {
  font-size: 18px;
  line-height: 1.3;

  @media (min-width: 40em) {
    font-size: 20px;
  }

  @media (min-width: 52em) {
    font-size: 22px;
  }

  @media (min-width: 64em) {
    font-size: 24px;
    line-height: 1.4;
  }
}
