
// Converted Variables

$line-height-1: 1 !default;
$line-height-2: 1.125 !default;
$line-height-3: 1.25 !default;
$line-height-4: 1.5 !default;
$letter-spacing: 1 !default;
$caps-letter-spacing: .2em !default;
$bold-font-weight: bold !default;

// Custom Media Query Variables


/* Basscss Typography */

.font-family-inherit { font-family: inherit }

.font-size-inherit { font-size: inherit }

.text-decoration-none { text-decoration: none }

.bold    { font-weight: $bold-font-weight /* Fallback value:  bold */ }

.regular { font-weight: normal }

.italic  { font-style: italic }

.caps    { text-transform: uppercase; letter-spacing: $caps-letter-spacing; }

.left-align   { text-align: left }

.center       { text-align: center }

.right-align  { text-align: right }

.justify      { text-align: justify }

.nowrap { white-space: nowrap }

.break-word { word-wrap: break-word }

.line-height-1 { line-height: $line-height-1 }

.line-height-2 { line-height: $line-height-2 }

.line-height-3 { line-height: $line-height-3 }

.line-height-4 { line-height: $line-height-4 }

.list-style-none { list-style: none }

.underline { text-decoration: underline }

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-reset {
  list-style: none;
  padding-left: 0;
}