
// Custom Media Query Variables


/* Basscss Background Colors */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/

.bg-black  {
  background-color: $black !important;
}

.bg-gray   {
  background-color: $gray !important;
}

.bg-silver {
  background-color: $silver !important;
}

.bg-white  {
  background-color: $white !important;
}

.bg-aqua  {
  background-color: $aqua !important;
}

.bg-blue  {
  background-color: $blue !important;
}

.bg-navy  {
  background-color: $navy !important;
}

.bg-teal  {
  background-color: $teal !important;
}

.bg-green {
  background-color: $green !important;
}

.bg-olive {
  background-color: $olive !important;
}

.bg-lime  {
  background-color: $lime !important;
}

.bg-yellow  {
  background-color: $yellow !important;
}

.bg-orange  {
  background-color: $orange !important;
}

.bg-red     {
  background-color: $red !important;
}

.bg-fuchsia {
  background-color: $fuchsia !important;
}

.bg-purple  {
  background-color: $purple !important;
}

.bg-maroon  {
  background-color: $maroon !important;
}
