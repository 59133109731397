
// Converted Variables

$button-color: #fff !default;
$button-background-color: $blue !default;
$border-radius: 3px !default;
$darken-1: rgba(0, 0, 0, .0625) !default;
$darken-2: rgba(0, 0, 0, .125) !default;
$darken-3: rgba(0, 0, 0, .25) !default;

// Custom Media Query Variables


/* Basscss Btn Primary */

.btn-primary {
  color: $button-color;
  background-color: $button-background-color;
  border-radius: $border-radius;
}

.btn-primary:hover {
  box-shadow: inset 0 0 0 20rem $darken-1;
}

.btn-primary:active {
  box-shadow: inset 0 0 0 20rem $darken-2,
    inset 0 3px 4px 0 $darken-3,
    0 0 1px $darken-2;
}

.btn-primary:disabled,
.btn-primary.is-disabled {
  opacity: .5;
}
