
// Converted Variables

$lighten-1: rgba(255, 255, 255, .0625) !default;
$lighten-2: rgba(255, 255, 255, .125) !default;
$lighten-3: rgba(255, 255, 255, .25) !default;
$lighten-4: rgba(255, 255, 255, .5) !default;

// Custom Media Query Variables


/* Basscss Lighten */

.bg-lighten-1 { background-color: $lighten-1 }

.bg-lighten-2 { background-color: $lighten-2 }

.bg-lighten-3 { background-color: $lighten-3 }

.bg-lighten-4 { background-color: $lighten-4 }