
// Custom Media Query Variables


/* Basscss Colors */

/*

   COLOR VARIABLES

   - Cool
   - Warm
   - Gray Scale

*/

.black  {
  color: $black !important;
}

.gray   {
  color: $gray !important;
}

.silver {
  color: $silver !important;
}

.white  {
  color: $white !important;
}

.aqua  {
  color: $aqua !important;
}

.blue  {
  color: $blue !important;
}

.navy  {
  color: $navy !important;
}

.teal  {
  color: $teal !important;
}

.green {
  color: $green !important;
}

.olive {
  color: $olive !important;
}

.lime  {
  color: $lime !important;
}

.yellow  {
  color: $yellow !important;
}

.orange  {
  color: $orange !important;
}

.red     {
  color: $red !important;
}

.fuchsia {
  color: $fuchsia !important;
}

.purple  {
  color: $purple !important;
}

.maroon  {
  color: $maroon !important;
}

.color-inherit { color: inherit }

.muted { opacity: .5 }
