
// Converted Variables


// Custom Media Query Variables


/* Basscss All */

.all-initial { all: initial }

.all-unset { all: unset }

.all-inherit { all: inherit }