
// Converted Variables


// Custom Media Query Variables


/* Basscss Background Images */

.bg-cover   { background-size: cover }

.bg-contain { background-size: contain }

.bg-center  { background-position: center }

.bg-top     { background-position: top }

.bg-right   { background-position: right }

.bg-bottom  { background-position: bottom }

.bg-left    { background-position: left }