p, li, h1, h2, h3, h4, h5, h6, table {
  word-wrap: break-word;
  color: $black;
}

.fa {
  vertical-align: initial;
}

.text-identity { font-family: $font-family-identity !important; }
.text-title { font-family: $heading-font-family !important; }
.text-body { font-family: $font-family !important; }
.text-sans { font-family: $font-family-sans-serif !important; }
.text-serif { font-family: $font-family-serif !important; }
.text-mono { font-family: $font-family-monospace !important; }
.text-bare { text-decoration: none; }

.space1 { letter-spacing: 0.1rem; }
.space2 { letter-spacing: 0.2rem; }
.space3 { letter-spacing: 0.3rem; }
.space4 { letter-spacing: 0.4rem; }
.space5 { letter-spacing: 0.5rem; }

.container { max-width: $container-width; }

@media #{$breakpoint-sm} {
  .sm-center { text-align: center !important; }
  .sm-right { text-align: right !important; }
  .sm-left { text-align: left !important; }
}

@media #{$breakpoint-md} {
  .md-center { text-align: center !important; }
  .md-right { text-align: right !important; }
  .md-left { text-align: left !important; }
}

@media #{$breakpoint-lg} {
  .lg-center { text-align: center !important; }
  .lg-right { text-align: right !important; }
  .lg-left { text-align: left !important; }
}

.pagination { font-family: $font-family; }

.dark { color: $dark !important; }
.bg-dark { background-color: $dark !important; }

.logo {
  font-family: $font-family-identity;
  font-weight: 300;
  text-decoration: none;
  letter-spacing: .2rem;
}

@media #{$breakpoint-sm} {
  .logo { letter-spacing: .5rem; }
}

/*
 * Animation module with all animation code
 */
.anim-text-flow,
.anim-text-flow-hover:hover {
  /*
   * Animation variables
   */
  $animationSteps: 20;
  $animationDuration: 50;
  $animationElement: span;
  $animationElementsCount: 100;
  $delayBetweenLetters: 0.2;

  /*
   * Elements settings
   */
  #{$animationElement} {
    animation-name: anim-text-flow-keys;
    animation-duration: #{$animationDuration}s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }

  /*
   * Keyframe loop
   */
  @keyframes anim-text-flow-keys {
    @for $i from 0 through $animationSteps {
      #{percentage($i * calc(1 / $animationSteps))} {
        color: hsla(random(365) * 1deg, 60%, 60%, 1);
      }
    }
  }

  /*
   * Element animation delay loop
   */
  $totalDelayTime: $animationElementsCount * $delayBetweenLetters;

  @for $i from 1 through $animationElementsCount {
    #{$animationElement}:nth-of-type(#{$i}) {
      animation-delay: #{($i * $delayBetweenLetters) - $totalDelayTime}s;
    }
  }
}
