
// Converted Variables


// Custom Media Query Variables


/* Basscss Align */

.align-baseline { vertical-align: baseline }

.align-top      { vertical-align: top }

.align-middle   { vertical-align: middle }

.align-bottom   { vertical-align: bottom }