
// Converted Variables

$z1: 1 !default;
$z2: 2 !default;
$z3: 3 !default;
$z4: 4 !default;

// Custom Media Query Variables


/* Basscss Position */

.relative { position: relative }

.absolute { position: absolute }

.fixed    { position: fixed }

.top-0    { top: 0 }

.right-0  { right: 0 }

.bottom-0 { bottom: 0 }

.left-0   { left: 0 }

.z1 { z-index: $z1 }

.z2 { z-index: $z2 }

.z3 { z-index: $z3 }

.z4 { z-index: $z4 }